import { Contract } from '@ethersproject/contracts'
import { Signer } from "ethers";
import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { isAddress } from './util'
import { BonesAndBananas__factory } from '../types/ethers-contracts/factories/BonesAndBananas__factory'

// export const CONTRACT_ADDRESS = process.env.CONTRACT_ADDRESS || '0x742442624207395a6a9B87d7Aa71650C1098a4b2'
export const CONTRACT_ADDRESS = process.env.CONTRACT_ADDRESS || '0xFBB6684EbD6093989740e8ef3e7D57cf3813E5a4'

export const Networks = {
  MainNet: 1,
  Rinkeby: 4,
}

function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}
// account is optional
export function getContract(address: string, Factory: ContractFactory, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return Factory.connect(address, getProviderOrSigner(library, account))
}

type ContractFactory<T extends Contract = Contract> = {
  connect(
    address: string,
    signerOrProvider: Signer | Web3Provider
  ): T
}

export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ContractFactory: ContractFactory<T>,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useWeb3React<Web3Provider>()

  return useMemo(() => {
    if (!addressOrAddressMap || !ContractFactory || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ContractFactory, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, library, chainId, withSignerIfPossible, account, ContractFactory]) as T
}

export function useBananasContract(withSignerIfPossible?: boolean) {
  // const { chainId } = useWeb3React()

  // address
  // let address: string | undefined
  // if (chainId) {
  //   switch (chainId) {
  //     case Networks.MainNet:
  //       address = ""
  //       break
  //     case Networks.Rinkeby:
  //       address = '0x742442624207395a6a9B87d7Aa71650C1098a4b2'
  //       break
  //   }
  // }

  return useContract(CONTRACT_ADDRESS, BonesAndBananas__factory, withSignerIfPossible)
}

