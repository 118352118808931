// import { useEffect, useState } from 'react'
// import { utils } from 'ethers'
// import { useSaleInfo } from './lib/hooks/useSaleInfo'
import { CONTRACT_ADDRESS } from './lib/useContract'
// import { useWeb3React } from '@web3-react/core'
// import { Web3Provider } from '@ethersproject/providers'
// import { InjectedConnector } from '@web3-react/injected-connector'
// // import { BonesAndBananas } from './types/ethers-contracts'

// export const injectedConnector = new InjectedConnector({
//   supportedChainIds: [
//     1, // Mainet
//     4, // Rinkeby
//   ],
// })

function App() {
  // const { account, activate, active } = useWeb3React<Web3Provider>()
  // const contract = useBananasContract() as BonesAndBananas
  // const {
  //   saleHasStarted,
  //   isFreeMintPeriod,
  //   saleStartTime,
  //   mintCost,
  //   maxSupply,
  //   currentSupply,
  //   hasClaimedFree,
  //   accountBalance
  // } = useSaleInfo()
  //  const connectWallet = async () => {
  //     if (active) {
  //       return
  //     }

  //     await activate(injectedConnector)
  //   }

  return (
    <div>
      <h1 className="heading-7">10,000/10,000 Bananas Minted</h1>

      <h1 className="heading-7">Bananas are sold out!</h1>

      <h4>
        View your bananas or buy more on the secondary market in our{' '}
        <a href="https://opensea.io/collection/bones-and-bananas">OpenSea collection</a>
      </h4>
      <div className="heading-7" style={{
        marginTop: '2rem',
        textAlign: 'center'
      }}>
        Verified Contract: {CONTRACT_ADDRESS}
      </div>
    </div>
  )

  // return (
  //   <div style={{ position: 'relative' }}>
  //     {active ? null :
  //       <div style={{
  //         position: 'absolute',
  //         top: 0,
  //         bottom: 0,
  //         left: 0,
  //         right: 0,
  //         zIndex: 50,
  //         backgroundColor: '#f8f8f8',
  //         opacity: '0.9',
  //         display: 'flex',
  //         alignItems: 'center',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         gap: '2rem'
  //       }}>
  //         <h1 className="heading-7">Connect your wallet to begin</h1>
  //         <button
  //           className="button-2 w-button"
  //           style={{
  //             fontSize: '30px'
  //           }}
  //           onClick={connectWallet}
  //         >
  //           Connect Wallet
  //         </button>
  //       </div>
  //     }
  //     {
  //       saleHasStarted ? <div>
  //         <h1 className="heading-7">{currentSupply}/{maxSupply} Bananas Minted</h1>
  //         <h5 className="heading-13">You currently hold: {accountBalance} Banana(s)! </h5>
  //         <div style={{ textAlign: 'center' }}>
  //           <div>View the Banana(s) you mint on our</div>
  //           <a href="https://opensea.io/collection/bones-and-bananas">OpenSea collection</a>
  //         </div>
  //       </div> : <div>
  //         <h1 className="heading-7">Sale will begin at {saleStartDescription}</h1>
  //       </div>
  //     }
  //     {!saleIsFinished && (
  //       <div className="wrapper">
  //         <div className="home-categories">
  //           <div
  //             data-w-id="79f09a57-05ab-ed81-c059-4d50244b2341"
  //             // style={{ opacity: "0" }}
  //             className="home-category-card"
  //           >
  //             <img src="/images/GetFreeImage.png" sizes="(max-width: 479px) 64vw, (max-width: 991px) 176px, 220px"
  //               // srcset="images/GetFreeImage-p-800.png 800w, images/GetFreeImage-p-1080.png 1080w, images/GetFreeImage.png 1200w"
  //               alt="" className="home-category-image-1" />
  //             <div className="home-category-info-1">
  //               <h5 className="heading-7">Got your FREE banana yet? </h5>
  //               {
  //                 (!saleHasStarted) ?
  //                   <div className="text-block-2">
  //                     Come back when the sale starts on {saleStartDescription} to claim your free banana.
  //                   </div> :
  //                   (isFreeMintPeriod) ?
  //                     hasClaimedFree ?
  //                       <div className="text-block-2">Looks like you&#x27;ve already minted your FREE banana. Use the Mint More button if you want to get more! </div> :
  //                       <div>
  //                         <div className="text-block-2" style={{ marginBottom: '.5rem' }}>Everyone can claim one banana for free (plus gas)</div>
  //                         <button
  //                           disabled={saleIsFinished}
  //                           className="button small white w-button"
  //                           onClick={async () => {
  //                             setErrorMessage(null)
  //                             await contract.claimFreeToken()
  //                           }}
  //                         >
  //                           Claim banana
  //                         </button>
  //                       </div>
  //                     :
  //                     <div className="text-block-2">Unfortunately the time to claim a free banana has expired. Use the other panel to mint additional bananas</div>
  //               }
  //             </div>
  //           </div>

  //           <div
  //             data-w-id="b498480a-a03d-afff-8227-912980ab2a0a"
  //             // style={{ opacity: "0" }}
  //             className="home-category-card red"
  //           >
  //             <img src="/images/GetMoreImage.png" sizes="(max-width: 479px) 60vw, (max-width: 991px) 176px, 220px"
  //               // srcset="images/GetMoreImage-p-500.png 500w, images/GetMoreImage-p-800.png 800w, images/GetMoreImage-p-1080.png 1080w, images/GetMoreImage.png 1200w"
  //               alt="" className="home-category-image-2" />
  //             <div className="home-category-info-2">
  //               <h5 className="heading-6">Want More Bananas?</h5>
  //               {saleHasStarted ?
  //                 <div>
  //                   <div className="text-block-3">
  //                     Enter the number of bananas you want to mint. Maximum 20 per transaction.
  //                     <br />‍
  //                   </div>
  //                   <div
  //                     style={{
  //                       display: 'flex',
  //                       flexDirection: 'column',
  //                       alignItems: 'center'
  //                     }}
  //                   >
  //                     <input
  //                       className="mint-count"
  //                       type='number'
  //                       max='20'
  //                       min='1'
  //                       value={mintCount}
  //                       disabled={saleIsFinished}
  //                       onChange={(e) => {
  //                         setMintCount(e.target.valueAsNumber)
  //                       }}
  //                     />
  //                     <div className="text-block-5 font-inconsolata">Price: {mintCost}E Each</div>
  //                     <button
  //                       className="button small white w-button"
  //                       disabled={saleIsFinished}
  //                       onClick={async () => {
  //                         try {
  //                           setErrorMessage(null)
  //                           await contract.mintTokens(mintCount, {
  //                             value: utils.parseEther(mintCost).mul(mintCount)
  //                           })
  //                         } catch (e) {
  //                           if (e.reason === "insufficient funds for intrinsic transaction cost") {
  //                             setErrorMessage("You don't have enough ETH in your wallet for that transaction")
  //                           }
  //                         }
  //                       }}
  //                     >
  //                       Mint More
  //                     </button>
  //                   </div>

  //                 </div> :
  //                 <div className="text-block-5 font-inconsolata">Come back at {saleStartDescription} to begin minting your bananas.</div>
  //               }
  //             </div>
  //           </div>

  //           {errorMessage && <h1 className="heading-13">{errorMessage}</h1>}
  //         </div>
  //       </div>
  //     )}
  //     <div className="heading-7" style={{
  //       marginTop: '2rem',
  //       textAlign: 'center'
  //     }}>
  //       Verified Contract: {CONTRACT_ADDRESS}
  //     </div>
  //   </div>
  // );
}

export default App;
